/*GENERAL DEFINITIONS*/
body {
  font-family: "Roboto", sans-serif;
  background-color: #161618;
  color: white;
  padding: 0;
  margin: 0;
  font-weight: bold;
}
/*SECTIONS*/
#header {
  width: 100vw;
  height: 100vh;
  background: url("../assets/bg-header.jpg") no-repeat;
  background-size: cover;
  display: grid;
  align-items: baseline;
}
#records {
  text-align: center;
  margin: 5%;
}
#spotify {
  line-height: 2;
  display: grid;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background: url("../assets/music.jpg") no-repeat;
  /* Set a specific height */
  min-height: 250px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(50%);
}
footer {
  display: grid;
  justify-content: center;
  margin: 3% 0;
}
#spotify h3 {
  margin: 10px;
}
footer div {
  margin: 8% auto;
}

/*ELEMENTS*/
.navbar {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 50px;
  cursor: pointer;
}

.play-button {
  cursor: pointer;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
  background: white;
  border-radius: 50%;
  animation: shadow-pulse 1s infinite;
}
.play-button::after {
  content: "";
  display: block;
  margin: 25px 30px;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 24px solid #454343;
}

.records {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.record {
  margin: 2% 10%;
}
.record img {
  border-radius: 5px;
  height: 250px;
  width: 100%;
  object-fit: cover;
}
.record:hover {
  cursor: pointer;
  filter: grayscale(100%);
  transition: all 1s;
}

.btn {
  cursor: pointer;
  justify-self: center;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 50px;
  padding: 15px 25px;
  border: none;
  outline: none;
}
.btn:hover {
  background-color: #161618;
  color: white;
  transition: all 1s;
}
.copyright {
  font-weight: normal;
}
.icons a {
  color: white;
  margin: 5px;
}
.icons i {
  font-size: 24px;
}
.player-container {
  /*position: relative;*/
  height: 100vh;
  /*display: block;*/
  /*margin: 15px auto;*/
}
.hidden {
  display: none;
}
.player-container ul {
  padding: 0;
  margin: 10px 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.player-container ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin: 5px;
  border: 1px solid white;
  border-radius: 6px;
  cursor: pointer;
}
.player-close {
  text-align: right;
  color: white;
  margin: 10px;
  cursor: pointer;
}
/*MEDIA QUERIES*/

@media (max-width: 650px) {
  #header {
    background: url(/static/media/bg-header.d8e3829b.jpg) no-repeat center;
    background-size: cover;
  }
  .navbar > div:not(:nth-last-of-type(3)) {
    display: none;
  }
  .records {
    grid-template-columns: 1fr;
  }
  .player-container {
    margin: 0;
    width: 100vw;
  }
}

/*KEYFRAMES*/

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.73);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}
